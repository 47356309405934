/**
 * !!! Do not edit directly. !!!
 */

export const borderRadiusSmall = "0.25rem";
export const borderRadiusMedium = "0.375rem";
export const borderRadiusLarge = "0.5rem";
export const borderLight = "1px solid #C4CBD5";
export const borderLighter = "1px solid #DCE0E6";
export const borderStandard = "1px solid #DCE0E6";
export const borderActive = "1px solid #0044B2";
export const boxShadowStandard = "0 3px 8px rgba(0, 0, 0, 0.2)";
export const boxShadowLight = "0 3px 4px rgba(0, 0, 0, 0.08)";
export const colorBlueDarkest = "#0042A1";
export const colorBlueDark = "#0044B2";
export const colorBlueMedium = "#0071D9";
export const colorBlueLight = "#CCE3F7";
export const colorBlueLighter = "#EBF4FC";
export const colorBlueLightest = "#F7F9FC";
export const colorBlueDevice = "#0084FF";
export const colorSteelDarkest = "#0A2347";
export const colorSteelDarker = "#445979";
export const colorSteelDark = "#6E7F99";
export const colorSteelMedium = "#8A97AB";
export const colorSteelLight = "#C4CBD5";
export const colorSteelLighter = "#DCE0E6";
export const colorSteelLightest = "#F0F2F5";
export const colorNeonGreen = "#B9F0B4";
export const colorNeonGreenDark = "#6CB74C";
export const colorNeonGreenLight = "#AAEBA4";
export const colorNeonGreenLightest = "#E8FFE5";
export const colorYellowLight = "#FFD84D";
export const colorYellowDark = "#E58600";
export const colorGrayLightest = "#FAFAFA";
export const colorGreyscale0 = "#000000";
export const colorGreyscale7 = "#121212";
export const colorGreyscale10 = "#191919";
export const colorGreyscale14 = "#242424";
export const colorGreyscale20 = "#333333";
export const colorGreyscale35 = "#595959";
export const colorGreyscale40 = "#666666";
export const colorGreyscale75 = "#A6A6A6";
export const colorGreyscale80 = "#CCCCCC";
export const colorGreyscale94 = "#F0F0F0";
export const colorBlack = "#000000";
export const colorWhite = "#ffffff";
export const colorGreen = "#00b398";
export const colorOrange = "#f2994a";
export const colorRedLight = "#d71715";
export const colorRedDark = "#860000";
export const colorProductAtac = "#7EBB42";
export const colorProductVdj = "#FDB716";
export const colorProductGex = "#ED295D";
export const colorProductDna = "#74d2e7";
export const colorProductFb = "#0B85C8";
export const colorProductCnv = "#7060AA";
export const colorProductSpatial = "#00ACA0";
export const colorProductAtacGex = "#B03772";
export const colorOutline = "rgba(0, 113, 217, 0.5)";
export const colorSuccessBase = "#00b398";
export const colorSuccessLight = "#EDFEFB";
export const colorErrorBase = "#d71715";
export const colorErrorLight = "#FEEDED";
export const colorWarningBase = "#f2994a";
export const colorWarningLight = "#FEF5ED";
export const colorGradientBlue = "linear-gradient(#0084FF, #0084FF)";
export const colorGradientGrey = "linear-gradient(#333333, #191919)";
export const colorGradientYellow = "linear-gradient(#FFD84D, #E58600)";
export const colorRed = "#d71715";
export const fontAssetCalibreRegularName = "Calibre";
export const fontAssetCalibreRegularWoff = "assets/CalibreWeb-Regular.woff";
export const fontAssetCalibreRegularWoff2 = "assets/CalibreWeb-Regular.woff2";
export const fontAssetCalibreMediumName = "Calibre";
export const fontAssetCalibreMediumWoff = "assets/CalibreWeb-Medium.woff";
export const fontAssetCalibreMediumWoff2 = "assets/CalibreWeb-Medium.woff2";
export const fontAssetCalibreSemiboldName = "Calibre";
export const fontAssetCalibreSemiboldWoff = "assets/CalibreWeb-Semibold.woff";
export const fontAssetCalibreSemiboldWoff2 = "assets/CalibreWeb-Semibold.woff2";
/**
 * Default font color and background-color for dark backgrounds
 */
export const fontColorBase = "#0A2347";
/**
 * Secondary font color
 */
export const fontColorSecondary = "#445979";
/**
 * Tertiary font color
 */
export const fontColorTertiary = "#445979";
/**
 * Font color for links
 */
export const fontColorLink = "#0071D9";
export const fontColorMuted = "#333333";
export const fontFamilyBase = "Calibre, sans-serif";
export const fontFamilyRegular = "Calibre, sans-serif";
export const fontFamilyMedium = "Calibre, sans-serif";
export const fontFamilySemibold = "Calibre, sans-serif";
export const fontFamilyMonospace = "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace";
export const fontLetterSpacingXxxxlarge = "-0.3px";
export const fontLetterSpacingXxxlarge = "-0.2px";
export const fontLetterSpacingXxlarge = "-0.2px";
export const fontLetterSpacingXlarge = "0px";
export const fontLetterSpacingLarge = "0px";
export const fontLetterSpacingMedium = "0.1px";
export const fontLetterSpacingSmall = "0.15px";
export const fontLetterSpacingXsmall = "0.2px";
export const fontLetterSpacingXxsmall = "0.25px";
export const fontLetterSpacingXxxsmall = "0.3px";
export const fontLetterSpacingCaps = "1px";
export const fontLineHeightXxxxlarge = ".9";
export const fontLineHeightXxxlarge = "1";
export const fontLineHeightXxlarge = "1.05";
export const fontLineHeightXlarge = "1.188";
export const fontLineHeightLarge = "1.333";
export const fontLineHeightMedium = "1.3";
export const fontLineHeightSmall = "1.333";
export const fontLineHeightXsmall = "1.375";
export const fontLineHeightXxsmall = "1.429";
export const fontLineHeightXxxsmall = "1.5";
export const fontLineHeightBase = "1.333";
/**
 * the value of 1rem
 */
export const fontBaseRem = "16px";
export const fontSizeXxxxlarge = "6rem";
export const fontSizeXxxlarge = "4rem";
export const fontSizeXxlarge = "3rem";
export const fontSizeXlarge = "2rem";
export const fontSizeLarge = "1.5rem";
export const fontSizeMedium = "1.25rem";
export const fontSizeSmall = "1.125rem";
export const fontSizeXsmall = "1rem";
export const fontSizeXxsmall = "0.875rem";
export const fontSizeXxxsmall = "0.75rem";
export const fontSizeXxxxsmall = "0.5rem";
export const fontSizeBase = "1.125rem";
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightSemibold = 600;
export const fontWeightBase = 400;
export const formInputWidth = "22.75rem";
export const layoutBannerHeight = "66px";
export const layoutWidth720 = "720px";
export const layoutWidth750 = "750px";
export const layoutWidth800 = "800px";
export const layoutWidth878 = "878px";
export const layoutWidth980 = "980px";
export const layoutWidth1106 = "1106px";
export const layoutWidth1200 = "1200px";
export const mediaPhoneOnly = "599px";
export const mediaTabletPortrait = "600px";
export const mediaTabletLandscape = "900px";
export const mediaDesktop = "1200px";
export const mediaDesktopBig = "1800px";
export const radialGradientBlue = "radial-gradient(59.1% 109.68% at 68.97% 103.72%, #0071D9 8.85%, #003796 100%)";
/**
 * 4px
 */
export const sizeXxsmall = "0.25rem";
/**
 * 8px
 */
export const sizeXsmall = "0.5rem";
/**
 * 12px
 */
export const sizeSmall = "0.75rem";
/**
 * 16px
 */
export const sizeMedium = "1rem";
/**
 * 24px
 */
export const sizeLarge = "1.5rem";
/**
 * 32px
 */
export const sizeXlarge = "2rem";
/**
 * 48px
 */
export const sizeXxlarge = "3rem";
/**
 * 72px
 */
export const sizeXxxlarge = "4.5rem";
export const spacing2 = "0.125rem";
export const spacing4 = "0.25rem";
export const spacing6 = "0.375rem";
export const spacing8 = "0.5rem";
export const spacing12 = "0.75rem";
export const spacing16 = "1rem";
export const spacing24 = "1.5rem";
export const spacing32 = "2rem";
export const spacing48 = "3rem";
export const spacing64 = "4rem";
export const spacing72 = "4.5rem";
export const spacing96 = "6rem";
export const spacing128 = "8rem";
export const spacing256 = "16rem";