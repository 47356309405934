import {
  borderRadiusMedium,
  borderStandard,
  boxShadowLight,
  boxShadowStandard,
  colorBlueDark,
  colorBlueLight,
  colorBlueLighter,
  colorBlueMedium,
  colorSteelLight,
  colorSteelMedium,
  colorWhite,
  fontFamilyBase,
  sizeSmall,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const backgrounds: Record<
  string,
  {
    disabled: string;
    hover: string;
    normal: string;
  }
> = {
  blue: {
    disabled: colorBlueLight,
    hover: colorBlueDark,
    normal: colorBlueMedium,
  },
  transparent: {
    disabled: "transparent",
    hover: "transparent",
    normal: "transparent",
  },
  white: {
    disabled: colorWhite,
    hover: "#E1EFFC",
    normal: colorWhite,
  },
};

export const borders: Record<string, { hover: string; normal: string }> = {
  blue: {
    hover: "none",
    normal: "none",
  },
  transparent: {
    hover: `1px solid ${colorWhite}`,
    normal: `1px solid ${colorWhite}`,
  },
  white: {
    hover: `1px solid ${colorBlueLight}`,
    normal: borderStandard,
  },
};

export const colors = {
  blue: {
    disabled: colorBlueLight,
    hover: colorBlueDark,
    normal: colorBlueMedium,
  },
  gray: {
    disabled: colorSteelLight,
    hover: colorSteelMedium,
    normal: colorSteelMedium,
  },
  white: {
    disabled: colorBlueLight,
    hover: colorBlueLighter,
    normal: colorWhite,
  },
};

export const shadows = {
  light: boxShadowLight,
  none: null,
  standard: boxShadowStandard,
};

// This is kind of weird but I wanted to use
// these styles for an anchor element, so I tried to
// decouple them safely
export interface StyleProps {
  /** An enumerated background color. */
  background?: "blue" | "red" | "transparent" | "white";
  /** Add a border for white or transparent buttons */
  border?: boolean;
  /** The button text color */
  color?: "blue" | "white" | "gray";

  /** An enumerated shadow type. */
  shadow?: "light" | "none" | "standard";
}

export const buttonCss = ({
  background = "blue",
  border = false,
  color = "white",
  shadow = "none",
}: StyleProps) => {
  return css`
    align-items: center;
    background: ${backgrounds[background]?.normal || "none"};
    border: ${(border && borders[background]?.normal) || "none"};
    border-radius: ${borderRadiusMedium};
    box-shadow: ${(shadow && shadows[shadow]) || "none"};
    box-sizing: border-box;
    color: ${colors[color]?.normal || "none"};
    cursor: pointer;
    display: flex;
    font-family: ${fontFamilyBase};
    height: ${sizeXxlarge};
    justify-content: center;
    max-width: 24rem;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    gap: ${sizeSmall};

    &:hover {
      background: ${backgrounds[background]?.hover || "none"};
      border: ${(border && borders[background]?.hover) || "none"};
      color: ${colors[color]?.hover || "none"};
    }

    &:disabled {
      background: ${backgrounds[background]?.disabled || "none"};
      color: ${colors[color]?.disabled || "none"};
      pointer-events: none;
    }

    /* Provide a fallback style for browsers
       that don't support :focus-visible */
    &:focus {
      outline: 2px auto ${colorBlueDark};
    }

    /* Remove the focus indicator on mouse-focus for
       browsers that support :focus-visible */
    &:focus:not(:focus-visible) {
      outline: none;
    }

    /* Focus style for keyboard-focus on browsers
       that support :focus-visible */
    &:focus-visible {
      outline: 2px auto ${colorBlueDark};
    }
  `;
};

/**
 * Accessible, standard, clickable / tappable button,
 * used to submit forms or perform other actions.
 *
 * Ensure that textual contents are wrapped in a `Text` component.
 */

export default styled("button")(buttonCss);
