import { MARKETO_PARAMS_EXPIRE_AT_KEY } from "@10x/site/utils/local-storage-keys";
import { add } from "date-fns";
import { intersection, isEqual } from "lodash-es";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDeepCompareEffect } from "react-use";

export const SHORT_PARAMS = Object.freeze(
  new Set(["cid", "cnm", "lss", "src"]),
);

// We're going to store values in the cookie using
// verbose replacement keys that match their names
// on the Marketo side:
export const SHORT_TO_VERBOSE_PARAMS = Object.freeze(
  new Map([
    ["cid", "campaignID"],
    ["cnm", "Last_Touch_Campaign__c"],
    ["lss", "Last_Touch_Subsource__c"],
    ["src", "Last_Touch_Source__c"],
  ]),
);

/**
 * Sites that link to 10xgenomics.com may pass URL params
 * indicating a source (eg. Facebook, LinkedIn), or other
 * data items of interest to Marketing efforts. Terraform
 * does not permit these params to remain on the URL
 * because they may interfere with other params that we
 * put there. The publications page, for example, relies
 * on URL params for preserving search state.
 *
 * Incoming params of interest to marketing are:
 *
 * * cid: represents the Salesforce Campaign ID.
 * * cnm: represents the Last Touch Campaign Name.
 * * lss: (lowercase L) represents Last Touch Subsource.
 * * src: represents Last Touch Source.
 *
 * The job of this hook is to strip these values from
 * the router and stash them in the cookie. The MarketoForm
 * component can retrieve them from there.
 */
export default function useMarketoParams() {
  // ex params
  // cid=1234567890&cnm=MegaCampaign&lss=last_touch_subsource&src=last_touch_source
  const { asPath, replace, pathname, query } = useRouter();

  // Expire in 24 hours
  const expires = useMemo(() => {
    return add(new Date(), { days: 1 });
  }, []);

  if (intersection(Object.keys(query), [...SHORT_PARAMS]).length !== 0) {
    try {
      localStorage.setItem(MARKETO_PARAMS_EXPIRE_AT_KEY, expires.toString());
    } catch (error) {
      console.log(error);
    }
  }

  const cleanedQuery = Object.entries(query).reduce((memo, [key, value]) => {
    if (SHORT_PARAMS.has(key)) {
      const verboseKey = SHORT_TO_VERBOSE_PARAMS.get(key)!;

      try {
        localStorage.setItem(verboseKey, String(value));
      } catch (error) {
        console.log(error);
      }

      return memo;
    }
    return {
      ...memo,
      [key]: value,
    };
  }, {});

  useDeepCompareEffect(() => {
    if (isEqual(query, cleanedQuery)) {
      return;
    }

    replace(
      {
        pathname,
        query: cleanedQuery,
      },
      undefined,
      {
        // Prevent page reload
        shallow: true,
      },
    );
  }, [asPath, pathname, cleanedQuery, query]);
}
