import useMarketoParams from "@10x/hooks/use-marketo-params";
import type { OptionalToNullable } from "@10x/netlify-cms-graphql/utils/types";
import {
  colorSteelDark,
  colorWhite,
  fontFamilyBase,
} from "@10xdev/design-tokens";
import { css, Global } from "@emotion/react";
import NextHead from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import type { FunctionComponent, ReactNode } from "react";

import Fonts from "../Fonts";
import { getDomain, getFullTitle } from "./utils";

type Props = OptionalToNullable<{
  author?: string;
  background?: string;
  children?: ReactNode;
  description?: string;
  keywords?: string;
  locale?: string;
  ogImage?: string;
  robots?: boolean;
  title?: string;
  type?: string;
  url?: string;
}>;

const Head: FunctionComponent<Props> = ({
  author,
  background,
  children,
  description,
  keywords,
  locale = "en_US",
  ogImage = "https://cdn.10xgenomics.com/image/upload/f_auto,q_auto/v1588029524/social-share-images/social-default.png",
  robots = true,
  title,
  type = "article",
}) => {
  const router = useRouter() || { asPath: "/" };
  const fullTitle = getFullTitle(title, router.asPath);
  const [currentPath] = router.asPath.split("?");
  const domain = getDomain();

  // Marketo hygiene
  useMarketoParams();

  return (
    <>
      <NextHead>
        <meta charSet={"UTF-8"} />
        <title>{fullTitle}</title>
        <link href={"/favicon.ico"} rel={"icon"} />

        {/* Generic metadata */}

        {author ? <meta content={author} name={"author"} /> : null}
        {description ? (
          <meta content={description} name={"description"} />
        ) : null}
        {keywords ? <meta content={keywords} name={"keywords"} /> : null}
        {robots ? null : <meta content={"none"} name={"robots"} />}
        <meta
          content={"width=device-width, initial-scale=1"}
          name={"viewport"}
        />

        {/* Languages */}

        <link
          href={`${domain}${currentPath}`}
          hrefLang={"en"}
          rel={"alternate"}
        />
        <link
          href={`${domain}/jp${currentPath}`}
          hrefLang={"ja"}
          rel={"alternate"}
        />
        <link
          href={`${domain}/cn${currentPath}`}
          hrefLang={"zh-hans"}
          rel={"alternate"}
        />
        <link
          href={`${domain}${currentPath}`}
          hrefLang={"x-default"}
          rel={"alternate"}
        />
        <link href={`${domain}${currentPath}`} rel={"canonical"} />

        {/* Facebook */}

        <meta
          content={"https://www.facebook.com/10xGenomics/"}
          property={"article:publisher"}
        />
        {description && (
          <meta content={description} property={"og:description"} />
        )}
        {ogImage && <meta content={ogImage} property={"og:image"} />}
        {locale && <meta content={locale} property={"og:locale"} />}
        <meta content={"10x Genomics"} property={"og:site_name"} />
        <meta content={fullTitle} property={"og:title"} />
        {type && <meta content={type} property={"og:type"} />}
        <meta content={`${domain}${currentPath}`} property={"og:url"} />
        {process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION ? (
          <meta
            content={process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION}
            name={"facebook-domain-verification"}
          />
        ) : null}

        {/* Twitter */}

        <meta content={"summary_large_image"} name={"twitter:card"} />
        <meta content={"@10xGenomics"} name={"twitter:creator"} />
        {description && (
          <meta content={description} name={"twitter:description"} />
        )}
        {ogImage && <meta content={ogImage} name={"twitter:image"} />}
        <meta content={"@10xGenomics"} name={"twitter:site"} />
        <meta content={fullTitle} name={"twitter:title"} />

        {/* Arbitrary stuff */}
        {children}
      </NextHead>

      {/* Global styles */}
      <Fonts />

      {/* Cookie banner */}
      <Script
        src={
          "https://cdn-cookieyes.com/client_data/7ea8487f801d8da618cfe9fe/script.js"
        }
        strategy={"beforeInteractive"}
      />

      {/* Cookieless tracking */}
      <Script
        data-domain={"10xgenomics.com"}
        defer
        src={"https://plausible.io/js/script.js"}
      />

      <Global
        styles={css`
          body {
            background: ${background || colorWhite};
            color: ${colorSteelDark};
            font-family: ${fontFamilyBase};
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />
    </>
  );
};

export default Head;
